var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2c2fa2fb2c94b7f9d25951c0875cc73eafc7c606"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

import { SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_SAMPLE_RATE } from './src/config/config';
import { denyUrls, allowUrls } from './src/lib/sentry/sentry.config';

/**
 * !NOTE!: This file must remain in the root of the project.
 * @see https://github.com/getsentry/sentry-javascript/issues/4249
 *
 * This file configures the initialization of Sentry on the client.
 * The config you add here will be used whenever a users loads a page in their browser.
 *
 * @see https://docs.sentry.io/platforms/javascript/guides/nextjs/
 */
Sentry.init({
    dsn: SENTRY_DSN,

    // Sets the environment. This string is free-form and not set by default. A release can be associated
    // with more than one environment to separate them in the UI
    environment: SENTRY_ENVIRONMENT,

    // An array of strings or regex patterns that match the URLs of scripts where errors have been created.
    // Only errors that have been created on these URLs will be sent to Sentry.
    allowUrls,

    // An array of strings or regex patterns that match the URLs of scripts where errors have been created.
    // Errors that have been created on these URLs won't be sent to Sentry.
    denyUrls,

    // Configures the sample rate for error events, in the range of 0.0 to 1.0.
    sampleRate: SENTRY_SAMPLE_RATE,

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
        Sentry.replayIntegration({
            // Additional Replay configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],
});
